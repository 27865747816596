import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Button from "components/button";
import QRCode from "react-qr-code";
import styled from "styled-components";
import logoPix from "./../../../assets/images/icons/logoPix.png";
import pixImage from "./../../../assets/images/pix-image.png";
import money from "./../../../assets/images/icons/money2.png";
import DepositStyleWrapper from "./DepositModal.Styles";
import ProgressBar from "components/progressBar/v3";
import { set } from "date-fns";
import { getAuth } from "firebase/auth";

const DepositModal = ({ isOpen, onRequestClose }) => {
  const [coupon, setCoupon] = useState("");
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(false);
  const [step, setStep] = useState(1);
  const [qrCodeValue, setQRCodeValue] = useState(
    "0IFZ6Q3C9ZBKW96QAIBH3Y62HGJMT2L9"
  );
  const [tansactionId, setTransactionId] = useState("");
  const [cpf, setCpf] = useState("");
  const [FullName, setFullName] = useState("");
  const [amount, setAmount] = useState("");
  const [fieldsFilled, setFieldsFilled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    if (cpf && FullName && amount) {
      setFieldsFilled(true);
    } else {
      setFieldsFilled(false);
    }
  }, [cpf, FullName, amount]);

  const callApiData = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error("Usuário não autenticado");
      }

      const token = await user.getIdToken();
      const response = await fetch(
        "https://us-central1-clash-fy.cloudfunctions.net/jwtUserDetails",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao chamar a API");
      }

      const data = await response.json();

      const name = data.name;
      const lastName = data.lastName;
      const cpf = data.cpf;

      setFullName(lastName ? name + " " + lastName : name);
      setCpf(cpf);
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    callApiData();
  }, []);

  const handleCpfChange = (event) => {
    let formattedCpf = event.target.value.replace(/\D/g, "");
    formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, "$1.$2");
    formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, "$1.$2");
    formattedCpf = formattedCpf.replace(/(\d{3})(\d{1,2})/, "$1-$2");
    setCpf(formattedCpf);
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
  };

  const handleCheckboxChange1 = () => {
    setChecked1(!checked1);
    setChecked2(false);
  };

  const handleCheckboxChange2 = () => {
    setChecked2(!checked2);
    setChecked1(false);
  };

  const nextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    }
  };

  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(qrCodeValue);
  };

  async function callApi() {
    const dados = {
      userId: "fJc7XYR6rjfHVNYblMvCkZbTVYc2",
      valor: amount,
    };

    try {
      const resposta = await fetch(
        "https://us-central1-clash-fy.cloudfunctions.net/Charge",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dados),
        }
      );

      if (!resposta.ok) {
        throw new Error("Erro ao chamar a API");
      }

      const dadosResposta = await resposta.json();
      setQRCodeValue(dadosResposta.qrCode);
      setTransactionId(dadosResposta.id);
      console.log("ID da transação:", dadosResposta.id);

      if (dadosResposta.id) {
        const idInterval = setInterval(
          () => checkTransactionId(dadosResposta.id),
          5000
        );
        setIntervalId(idInterval);
      }
    } catch (erro) {
      console.error("Ocorreu um erro:", erro);
    }
  }

  function checkTransactionId(transactionId) {
    console.log("Verificando ID da transação:", transactionId);
  }

  const handleClick = async () => {
    setLoading(true);

    try {
      await callApi();
      setStep(step + 1);
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
    } finally {
      setLoading(false);
    }
  };

  let stepContent;
  if (step === 1) {
    stepContent = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <div className="col-lg-5 offset-lg-2">
            <div className="page_header_progressbar">
              <span>PASSO 1 DE 3</span>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <ProgressBar progress="33%" />
        </div>
        <DepositStyleWrapper>
          <h1 style={{ textAlign: "center" }}>Selecione o cupom</h1>

          <div>
            <CustomCheckbox
              type="checkbox"
              id="checkbox1"
              checked={checked1}
              onChange={handleCheckboxChange1}
            />
            <CheckboxLabel htmlFor="checkbox1">
              Sem bônus na compra
            </CheckboxLabel>
          </div>

          <div>
            <CustomCheckbox
              type="checkbox"
              id="checkbox2"
              checked={checked2}
              onChange={handleCheckboxChange2}
            />
            <CheckboxLabel htmlFor="checkbox2">50% de bonus</CheckboxLabel>
          </div>
          <div className="kyc_user_form">
            <h6>Adicionar cupom de desconto</h6>
            <input
              type="text"
              placeholder="Cupom de desconto"
              className="form-control"
            />
          </div>
        </DepositStyleWrapper>
      </>
    );
  } else if (step === 2) {
    stepContent = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <div className="col-lg-5 offset-lg-2">
            <div className="page_header_progressbar">
              <span>PASSO 2 DE 3</span>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <ProgressBar progress="66%" />
        </div>
        <div>
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
            Método de Pagamento
          </h1>
          <h5>Recomendado</h5>
          <div
            style={{
              border: "2px solid white",
              padding: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              opacity: "0.5",
              transition: "opacity 0.3s",
              cursor: "pointer",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.opacity = "1";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.opacity = "0.5";
            }}
            onClick={nextStep}
          >
            <span>Pix</span>
            <img
              src={logoPix}
              alt="Ícone"
              style={{
                width: "30px",
                height: "30px",
                transition: "opacity 0.3s",
              }}
            />
          </div>
        </div>
      </>
    );
  } else if (step === 3) {
    stepContent = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <div className="col-lg-5 offset-lg-2">
            <div className="page_header_progressbar">
              <span>PASSO 3 DE 3</span>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <ProgressBar progress="100%" />
        </div>
        <DepositStyleWrapper>
          <div style={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h1 className="from_title">Digite as informações</h1>
            </div>
            <div className="kyc_user_form">
              <h5>CPF</h5>
              <input
                type="text"
                placeholder="___.___.___-__"
                maxLength="14"
                value={cpf}
                onChange={handleCpfChange}
              />
              <div className="row">
                <div className="form-group col-md-6">
                  <h5 style={{ alignSelf: "flex-start" }}>Nome Completo</h5>
                  <input
                    type="text"
                    placeholder=""
                    className="form-control"
                    style={{
                      width: "44vh",
                      marginRight: "10px",
                      alignSelf: "flex-start",
                    }}
                    value={FullName}
                    onChange={handleFullNameChange}
                  />
                </div>
              </div>
              <h5 style={{ alignSelf: "flex-start", marginTop: "-20px" }}>
                Valor (BRL)
              </h5>
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  className="btn btn-primary"
                  style={{
                    height: "50px",
                    backgroundColor: amount === 10 ? "red" : "#3e3f57",
                  }}
                  onClick={() => setAmount(10)}
                >
                  10
                </Button>
                <Button
                  className="btn btn-primary"
                  style={{
                    height: "50px",
                    backgroundColor: amount === 20 ? "red" : "#3e3f57",
                  }}
                  onClick={() => setAmount(20)}
                >
                  20
                </Button>
                <Button
                  className="btn btn-primary"
                  style={{
                    height: "50px",
                    backgroundColor: amount === 30 ? "red" : "#3e3f57",
                  }}
                  onClick={() => setAmount(30)}
                >
                  30
                </Button>
              </div>
              <input
                type="text"
                placeholder=""
                className="form-control"
                value={amount}
                onChange={handleAmountChange}
                onKeyUp={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  if (e.target.value.trim() !== "") {
                    e.target.value = parseFloat(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </DepositStyleWrapper>
      </>
    );
  } else if (step === 4) {
    stepContent = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <div className="col-lg-5 offset-lg-2">
            <div className="page_header_progressbar">
              <span>PASSO 3 DE 3</span>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <ProgressBar progress="100%" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <h1 style={{ textAlign: "center" }}>Leia o QrCode</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={pixImage}
              alt="Pix"
              style={{
                width: "15vh",
                height: "5vh",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            />
            <h3 style={{ margin: 0, marginLeft: "50px" }}>(BRL) {amount}.00</h3>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid white",
              borderRadius: "10px",
              padding: "10px",
              backgroundColor: "white",
            }}
          >
            <QRCode
              value={qrCodeValue}
              size={200}
              bgColor="#ffffff"
              fgColor="#000000"
              level="Q"
              includeMargin={true}
              renderAs="svg"
              imageSettings={{
                src: "/path/to/logo.png",
                excavate: true,
                height: 40,
                width: 40,
              }}
            />
          </div>

          <div
            style={{
              marginBottom: "50px",
              textAlign: "left",
              marginTop: "30px",
            }}
          >
            <h6>1. Escaneie o QrCode acima com seu aplicativo de pix</h6>
            <h6>2. Conclua o depósito com seu banco</h6>
            <h6>
              3. Seu (BRL) {amount} e qualquer bônus de depósito aplicável será
              creditado
            </h6>
          </div>
        </div>
      </>
    );
  } else if (step === 5) {
    stepContent = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
            marginTop: "-100px",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "60px",
          }}
        >
          <h1 style={{ textAlign: "center" }}>
            Deposito realizado com sucesso!
          </h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // border: "2px solid black",
              borderRadius: "100px",
              padding: "20px",
              // backgroundColor: "white"
              marginTop: "20px",
            }}
          >
            <img src={money} alt="Pix" style={{ width: "170px" }} />
          </div>

          <div
            style={{
              marginBottom: "-550px",
              textAlign: "left",
              marginTop: "40px",
            }}
          >
            <h6>Transação ID: {tansactionId}</h6>
            <h6>Quantia: (BRL) {amount}</h6>
          </div>
        </div>
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Deposit Modal"
      style={{
        overlay: modalOverlayStyles,
        content: modalContentStyles,
      }}
    >
      <span style={closeButtonStyles} onClick={onRequestClose}>
        ×
      </span>
      <div style={{ position: "relative" }}>
        {loading && (
          <div className="loading-overlay">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <div className="col-lg-5 offset-lg-2">
                <div className="page_header_progressbar">
                  <span>PASSO 3 DE 3</span>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <ProgressBar progress="100%" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  border: "8px solid #f3f3f3",
                  borderTop: "8px solid #3498db",
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  animation: "spin 1s linear infinite",
                }}
              ></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
              <h2 style={{ marginTop: "15px" }}>Carregando...</h2>
            </div>
          </div>
        )}
        {!loading && stepContent}{" "}
      </div>
      {!loading && (
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            marginTop: "20px",
            width: "83%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <div>
            {step !== 1 && (
              <Button
                style={{
                  width: "150px",
                  backgroundColor: "red",
                }}
                onClick={previousStep}
              >
                Voltar
              </Button>
            )}
          </div>
          <div>
            {step !== 2 && step !== 3 && step !== 4 && step !== 5 && (
              <Button
                style={{
                  width: "150px",
                  backgroundColor: "green",
                }}
                onClick={nextStep}
              >
                Proximo
              </Button>
            )}
          </div>
          <div>
            {step === 3 && fieldsFilled && (
              <Button
                style={{
                  width: "150px",
                  backgroundColor: "green",
                }}
                onClick={handleClick}
              >
                Proximo
              </Button>
            )}
          </div>
          <div>
            {step === 4 && (
              <Button
                style={{
                  width: "150px",
                  backgroundColor: "green",
                }}
                onClick={handleCopyClick}
              >
                Copiar Código
              </Button>
            )}
          </div>
          <div>
            {step === 5 && (
              <Button
                style={{
                  width: "150px",
                  height: "40px",
                  backgroundColor: "Red",
                  marginLeft: "15px",
                }}
                onClick={onRequestClose}
              >
                Fechar
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DepositModal;

const modalOverlayStyles = {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
};

const modalContentStyles = {
  top: "50%",
  left: "50%",
  right: "auto",
  minWidth: "500px",
  minHeight: "700px",
  maxWidth: "500px",
  maxHeight: "800px",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#151625",
  padding: "50px",
  borderRadius: "8px",
  border: "none",
};

const closeButtonStyles = {
  cursor: "pointer",
  position: "absolute",
  top: "10px",
  right: "10px",
  color: "white",
  fontSize: "20px",
};

const stepStyles = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const ChangeButton = styled.button`
  margin-top: 20px;
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const RandomButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6c757d;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #5a6268;
  }
`;

const InputField = styled.input`
  margin-top: 10px;
  padding: 5px;
  font-size: 16px;
  width: 300px;
`;

const inputContainerStyles = {
  marginTop: "10px",
};

const CustomCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 10px;

  &:checked::after {
    content: "X";
    font-size: 14px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CheckboxLabel = styled.label`
  color: white;
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 10px;
`;

const loadingOverlayStyles = `
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
`;
